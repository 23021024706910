import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>; //TODO Add loading spinner
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}

export const auth0 = {
  domain: "pricing.uk.auth0.com",
  clientId: "i7HXRP1NBAHIViG8sU00gNNEr2INDd5H",
};
