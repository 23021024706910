import { apiUrl } from "../api";
import { ApiRoutes } from "@pricing/shared";

export const createNewJob = async ({
  priceConfigId,
  token,
}: {
  priceConfigId: string;
  token: string;
}) => {
  const response = await fetch(
    `${apiUrl}/${ApiRoutes.priceConfig.run(priceConfigId)}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  console.log(Object.entries(response.headers.entries()));

  if (response.status === 202) {
    console.log(response.headers);
    const apiToPoll =
      response.headers.get("Location") || response.headers.get("location");
    console.log({ apiToPoll });

    if (!apiToPoll) {
      throw new Error("Cannot find path to poll from Location response header");
    }

    return {
      apiToPoll,
    };
  }

  throw new Error("Encountered an error running the price config job");
};

export const sleep = async (timeoutMs: number) => {
  await new Promise((res) => setTimeout(res, timeoutMs));
};

export const pollApiForResults = async ({
  apiToPoll,
  token,
}: {
  apiToPoll: string;
  token: string;
}) => {
  const response = await fetch(`${apiToPoll}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
};
