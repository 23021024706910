import { FormButton } from "./components/FormButton";
import { useAuth0 } from "@auth0/auth0-react";
import { FormEvent, useState } from "react";
import { createNewJob, pollApiForResults, sleep } from "./jobs/CreateNewJob";
import { Usage, useUsage } from "./hooks/useUsage";
import { LinkButton } from "./components/LinkButton";
import { webRoutes } from "./routes";

import { Credits } from '@pricing/shared';

export const StartJobButton = ({
  priceConfigId,
}: {
  priceConfigId: string;
}) => {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsRunning(true);

    const token = await getAccessTokenSilently();

    const { apiToPoll } = await createNewJob({ priceConfigId, token });

    console.log({ apiToPoll });
    let status = "";
    let count = 0;

    while (status !== "Finished") {
      await sleep(3_000);
      const response = await pollApiForResults({ apiToPoll, token });

      status = response.status;
      count = count + 1;
      console.log(`Api poll response status: ${status}`);

      if (count >= 10) {
        throw new Error(`Encountered error trying to poll ${apiToPoll}`);
      }
    }

    setIsRunning(false);
  };

  const { usage } = useUsage();

  const hasEnoughRemainingCredit = (usage: Usage): boolean => {
    const remaining = usage.limit - usage.used;

    const creditsRequiredToRunScrape = Credits.jobRun; //TODO this can probably be better calculated in the future

    return remaining >= creditsRequiredToRunScrape;
  };

  const canRunJob = usage && hasEnoughRemainingCredit(usage);

  return (
    <>
      {
        <p className={"text-sm text-gray-500 mb-2"}>
          {canRunJob && `You have used ${usage.used} of ${usage.limit} credits`}
        </p>
      }
      {canRunJob && (
        <form onSubmit={submitForm}>
          <FormButton
            text="Start new job"
            type="submit"
            isLoading={isRunning}
            disabled={isRunning}
          />
        </form>
      )}
      {!canRunJob && (
        <>
          <p className={"text-sm text-gray-500 mb-2"}>
            You do not have enough credits left to process this job
          </p>
          <LinkButton
            text={"Upgrade your plan"}
            to={webRoutes.plans.upgrade()}
          />
        </>
      )}
    </>
  );
};
