import { ApiRoutes, PriceConfigDto } from "@pricing/shared";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { apiUrl } from "./api";
import { fetchWithToken } from "./fetchWithToken";

export function usePriceConfig({ priceConfigId }: { priceConfigId: string }): {
  priceConfig: PriceConfigDto | null;
  loading: boolean;
} {
  const { getAccessTokenSilently } = useAuth0();
  const { data: priceConfig, error } = useSWR(
    [
      getAccessTokenSilently,
      `${apiUrl}/${ApiRoutes.priceConfig.id(priceConfigId)}`,
    ],
    fetchWithToken
  );

  const isLoading = !error && !priceConfig;

  return {
    priceConfig,
    loading: isLoading,
  };
}

export function usePriceConfigs(): {
  priceConfigs: PriceConfigDto[] | null;
  loading: boolean;
} {
  const { getAccessTokenSilently } = useAuth0();

  const { data: priceConfigs, error } = useSWR(
    [getAccessTokenSilently],
    fetchPriceConfigsWithToken
  );

  const isLoading = !error && !priceConfigs;

  return {
    priceConfigs,
    loading: isLoading,
  };
}

const fetchPriceConfigsWithToken = async ([getToken]: [
  getAccessToken: () => Promise<string>
]) => {
  const token = await getToken();

  return fetch(`${apiUrl}/${ApiRoutes.priceConfig.all()}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => res.json());
};
