import { Link } from "react-router-dom";

type Props = {
  text: string;
  to: string;
};

export function LinkButton({ text, to }: Props) {
  return (
    <div>
      <Link
        className={
          "ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 items-center bg-indigo-600 hover:bg-indigo-700"
        }
        to={to}
      >
        {text}
      </Link>
    </div>
  );
}
