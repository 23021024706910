import TextInput from "./TextInput";

type Props = {
  error: string | null;
  validateOnInputExit: (target: EventTarget & HTMLInputElement) => void;
  fieldName: string;
  label: string;
  value: string;
  disabled?: boolean;
};
export default function TextInputWithLabel({
  error,
  validateOnInputExit,
  fieldName,
  label,
  value,
  disabled,
}: Props) {
  return (
    <>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <TextInput
          error={error}
          validateOnInputExit={validateOnInputExit}
          fieldName={fieldName}
          value={value}
          disabled={disabled}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="first-name-error">
          {error}
        </p>
      )}
    </>
  );
}
