import { ApiRoutes } from "@pricing/shared";
import { fetchWithToken } from "../fetchWithToken";
import { useAuth0 } from "@auth0/auth0-react";
import { apiUrl } from "../api";
import useSWR from "swr";

export type Usage = {
  feature: string;
  used: number;
  limit: number;
};

export function useUsage(): {
  usage: Usage | null;
  isLoading: boolean;
} {
  const { getAccessTokenSilently } = useAuth0();

  const { data: usage } = useSWR(
    [getAccessTokenSilently, `${apiUrl}/${ApiRoutes.plans.usage()}`],
    fetchWithToken
  );

  return {
    usage,
    isLoading: false,
  };
}
