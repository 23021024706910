import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "./auth";
import LogoutPage from "./pages/LogoutPage";
import ProfilePage from "./pages/ProfilePage";
import Layout from "./pages/Layout";
import { PageName } from "./components/SidebarNavigation";
import NewPricingConfigurationPage from "./pages/NewPricingConfigurationPage";
import PricingConfigurationPage from "./pages/PricingConfigurationPage";
import UpgradePage from "./pages/UpgradePage";
import { webRoutes } from "./routes";

export function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Layout pageName={PageName.Home}>
              <DashboardPage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Layout pageName={PageName.Profile}>
              <ProfilePage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={webRoutes.plans.upgrade()}
        element={
          <RequireAuth>
            <Layout pageName={PageName.Upgrade}>
              <UpgradePage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={webRoutes.priceConfiguration.new()}
        element={
          <RequireAuth>
            <Layout pageName={PageName.PricingConfigurationNew}>
              <NewPricingConfigurationPage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path={webRoutes.priceConfiguration.id()}
        element={
          <RequireAuth>
            <Layout pageName={PageName.PricingConfiguration}>
              <PricingConfigurationPage />
            </Layout>
          </RequireAuth>
        }
      />

      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
    </Routes>
  );
}
