import {ReactComponent as LoadingSpinnerSmall} from "../svg/LoadingSpinnerSmall.svg";

type Props = {
  text: string;
  isLoading?: boolean;
  type: "button" | "submit";
  disabled: boolean;
};

export function FormButton({ text, isLoading = false, type, disabled }: Props) {
  return (
    <button
      type={type}
      className={"ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 items-center ".concat(
        disabled
          ? "bg-gray-400 hover:bg-gray-500"
          : "bg-indigo-600 hover:bg-indigo-700"
      )}
      disabled={disabled}
    >
      {isLoading && <LoadingSpinnerSmall />}
      {text}
    </button>
  );
}
