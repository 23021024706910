import {PageName, SidebarNavigation} from "../components/SidebarNavigation";
import React from "react";

type Props = {
  children?: React.ReactNode;
  pageName: PageName
};

export default function Layout({ children, pageName }: Props) {
  return (
    <div className="min-h-full">
      <SidebarNavigation currentPage={pageName} />
      <div className="flex flex-1 flex-col lg:pl-64">
        <main className="flex-1 pb-8">{children}</main>
      </div>
    </div>
  );
}
