export class ApiRoutes {
    static user = {
        profile: (userId) => `user/${userId}/profile`
    };
    static priceConfig = {
        new: () => `price-config/new`,
        all: () => `price-configs/all`,
        id: (id) => id ? `price-config/${id}` : `price-config/:priceConfigId`,
        run: (id) => id ? `price-config/${id}/run` : `price-config/:priceConfigId/run`,
        jobs: (id) => id ? `price-config/${id}/jobs` : `price-config/:priceConfigId/jobs`,
        job: (id, jobId) => id && jobId ? `price-config/${id}/job/${jobId}` : `price-config/:priceConfigId/job/:jobId`,
    };
    static plans = {
        upgrade: () => `plans/upgrade`,
        usage: () => `plans/usage`
    };
}
