import React from "react";
import { LinkButton } from "../components/LinkButton";
import { PriceConfigTable } from "../components/PriceConfigTable";
import { webRoutes } from "../routes";
import { UsagePanel } from "../components/UsagePanel";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardPage() {
  return (
    <>
      <div className="flex flex-row lg:px-8 md:px-6 py-6 justify-between">
        <div className="self-center justify-self-start flex-column">
          <h1 className="text-2xl font-bold leading-6 text-gray-900">
            Overview
          </h1>
          <UsagePanel />
        </div>

        <div className="flex bg-white mt-6 md:mt-0 lg:max-w-6xl md:items-center md:justify-end space-x-3">
          <LinkButton
            to={webRoutes.priceConfiguration.new()}
            text="Setup new configuration"
          />
        </div>
      </div>

      <h2 className="mx-auto font-bold mt-8 max-w-6xl px-4 text-lg leading-6 text-gray-900 sm:px-6 lg:px-8">
        Pricing configurations
      </h2>

      <PriceConfigTable />
    </>
  );
}
