import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <div>
      <h1 className="text-3xl font-bold underline">Home page</h1>
      <Link to="/dashboard">Link to dashboard</Link>
    </div>
  );
}
