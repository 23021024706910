import { PriceConfigJobsTable } from "./PriceConfigJobsTable";
import { StartJobButton } from "./StartJobButton";

type Props = { priceConfigId: string };

export const PriceConfigJobs = ({ priceConfigId }: Props) => {
  return (
    <div className="pt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
        Job runs
      </h2>
      <StartJobButton priceConfigId={priceConfigId} />
      <PriceConfigJobsTable priceConfigId={priceConfigId} />
    </div>
  );
};
