import { useAuth0 } from "@auth0/auth0-react";
import { FormEvent, useState } from "react";
import Notification, { Level } from "../components/Notification";
import TextInputWithLabel from "../components/TextInputWithLabel";
import { ApiRoutes, UserProfileDto } from "@pricing/shared";
import { useUser } from "../useUser";
import { FormButton } from "../components/FormButton";
import { apiUrl } from "../api";

type UserProfileParams = { firstName: string; lastName: string; email: string };

async function updateUserProfile(
  params: UserProfileParams,
  token: string,
  userId: string
): Promise<Response> {
  return await fetch(`${apiUrl}/${ApiRoutes.user.profile(userId)}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}

export default function ProfilePage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useUser();

  const [formError, setFormError] = useState<string | null>(null);
  const [showSaved, setShowSaved] = useState(Boolean);

  const [firstNameError, setFirstNameError] = useState<string | null>(null);

  async function submitForm(
    e: FormEvent<HTMLFormElement>,
    user: UserProfileDto
  ) {
    e.preventDefault();

    if (firstNameError) {
      setFormError(
        "There was an error with your form, please check it and try again"
      );
      return;
    }

    const elements = e.currentTarget.elements;

    const firstName = (elements.namedItem("first-name") as HTMLInputElement)
      .value;
    const lastName = (elements.namedItem("last-name") as HTMLInputElement)
      .value;
    const email = (elements.namedItem("email") as HTMLInputElement).value;

    setShowSaved(false);
    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const response = await updateUserProfile(
        { firstName, lastName, email },
        token,
        user.userId
      );

      if (response.status >= 400 && response.status < 500) {
        setFormError(
          "There was an error with your form, please check it and try again"
        );
      } else {
        setFormError(null);
        setShowSaved(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function validateFirstName(target: EventTarget & HTMLInputElement) {
    switch (target.value) {
      case "": {
        setFirstNameError(
          "First name must have at least 1 character that is a letter"
        );
        break;
      }
      default: {
        setFirstNameError(null);
      }
    }
  }

  return (
    user && (
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8 pt-6">
        {formError && (
          <Notification
            message={formError}
            level={Level.Warn}
            onClickClose={() => {
              setFormError(null);
            }}
          />
        )}
        {showSaved && (
          <Notification
            message={"Saved"}
            level={Level.Success}
            onClickClose={() => {
              setShowSaved(false);
            }}
          />
        )}
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={(e) => submitForm(e, user)}
        >
          <div className="space-y-6 divide-y divide-gray-200">
            <div>
              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                Profile
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Information about your profile that we will use to contact you
                about your service.
              </p>
            </div>

            <div className="pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Personal Information
                </h3>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <TextInputWithLabel
                    label="First name"
                    fieldName="first-name"
                    error={firstNameError}
                    validateOnInputExit={validateFirstName}
                    value={user.firstName}
                  />
                </div>
                <div className="sm:col-span-2">
                  <TextInputWithLabel
                    label="Last name"
                    fieldName="last-name"
                    error=""
                    validateOnInputExit={() => {}}
                    value={user.lastName}
                  />
                </div>
                <div className="sm:col-span-4">
                  <TextInputWithLabel
                    label="Email address"
                    fieldName="email"
                    error=""
                    validateOnInputExit={() => {}}
                    value={user.email}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <FormButton
                text="Save"
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    )
  );
}
