import { FormEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { usePriceConfig } from "../usePriceConfigs";
import { useAuth0 } from "@auth0/auth0-react";
import { apiUrl } from "../api";
import { ApiRoutes, PriceConfigDto } from "@pricing/shared";
import { PriceConfigForm } from "../components/PriceConfigForm";
import { PriceConfigJobs } from "../PriceConfigJobs";

async function updatePriceConfig(
  priceConfigId: string,
  params: { name: string; url: string },
  token: string
): Promise<Response> {
  return await fetch(`${apiUrl}/${ApiRoutes.priceConfig.id(priceConfigId)}`, {
    method: "PUT",
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}

export default function PricingConfigurationPage() {
  const { getAccessTokenSilently } = useAuth0();
  const { priceConfigId } = useParams();

  if (!priceConfigId) {
    throw new Error("No price config id found on PricingConfigurationPage");
  }

  const { priceConfig } = usePriceConfig({
    priceConfigId,
  });

  const [, setFormError] = useState<string | null>(null);
  const [, setShowSaved] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function submitForm(
    e: FormEvent<HTMLFormElement>,
    { priceConfigId }: PriceConfigDto
  ) {
    e.preventDefault();

    const elements = e.currentTarget.elements;

    const name = (elements.namedItem("name") as HTMLInputElement).value;
    const url = (elements.namedItem("url") as HTMLInputElement).value;

    setShowSaved(false);
    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const response = await updatePriceConfig(
        priceConfigId,
        { name, url },
        token
      );

      if (response.status >= 400 && response.status < 500) {
        setFormError(
          "There was an error with your form, please check it and try again"
        );
      } else {
        setFormError(null);
        setShowSaved(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8 pt-6">
        <div className="space-y-6 divide-y divide-gray-200">
          <div>
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
              Existing pricing configuration page
            </h1>
          </div>
          {priceConfig && (
            <>
              <PriceConfigForm
                priceConfig={priceConfig}
                isLoading={isLoading}
                onSubmit={(e) => submitForm(e, priceConfig)}
              />

              <PriceConfigJobs priceConfigId={priceConfig.priceConfigId} />
            </>
          )}
        </div>
      </div>
    </>
  );
}
