import { Link } from "react-router-dom";
import { classNames } from "../pages/DashboardPage";
import {NavigationProps, PageName} from "./SidebarNavigation";

export function isCurrentPage(
  page: PageName,
  currentPage: PageName
) {
  return page === currentPage;
}

export default function DesktopSidebar({
  secondaryNavigation,
  navigation,
  currentPage,
}: NavigationProps) {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-grow flex-col overflow-y-auto bg-cyan-700 pt-5 pb-4">
        <div className="flex flex-shrink-0 items-center px-4">
          <img
            className="h-8 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
            alt="Easywire logo"
          />
        </div>
        <nav
          className="mt-5 flex flex-1 flex-col divide-y divide-cyan-800 overflow-y-auto"
          aria-label="Sidebar"
        >
          <div className="space-y-1 px-2">
            {navigation.map((item) => {
              const current = isCurrentPage(item.name, currentPage);
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    current
                      ? "bg-cyan-800 text-white"
                      : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                    "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  )}
                  aria-current={current ? "page" : undefined}
                >
                  <item.icon
                    className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              );
            })}
          </div>
          <div className="mt-6 pt-6">
            <div className="space-y-1 px-2">
              {secondaryNavigation.map((item) => {
                const current = isCurrentPage(item.name, currentPage);

                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      current
                        ? "bg-cyan-800 text-white"
                        : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                      "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                    )}
                    aria-current={current ? "page" : undefined}
                  >
                    <item.icon
                      className="mr-4 h-6 w-6 text-cyan-200"
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
