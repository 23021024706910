import React from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function LoginPage() {
  const { user } = useAuth0();

  if (user) {
    return <Navigate to="/dashboard" replace />
  }

  return (
    <div>
      <h1 className="text-3xl font-bold underline">Login page</h1>
      <Link to="/" style={{display: 'block'}}>Link to home</Link>
      <Link to="/dashboard" style={{display: 'block'}}>Link to dashboard</Link>

      <LoginButton />
    </div>
  );
}

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};
