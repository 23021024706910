import { useUsage } from "../hooks/useUsage";

export const UsagePanel = () => {
  const { usage } = useUsage();

  return (
    <p className={"text-sm text-gray-500 mt-1"}>
      {usage
        ? `You have ${usage.limit - usage.used} credits remaining this month`
        : ""}
    </p>
  );
};

