type Config = {
  publicApiUrl: string
}

export const config: Config = {
  publicApiUrl: getEnv('REACT_APP_PUBLIC_API_URL', 'http://localhost:9000'),
};

export function getEnv(key: string, defaultValue?: any): string {
  const envElement = process.env[key];
  if (!envElement) {
    if (defaultValue) return defaultValue;

    throw new Error(`${key} not loaded as an environment variable`);
  }

  return envElement;
}
