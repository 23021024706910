import {
  Bars3CenterLeftIcon,
  HomeIcon,
  UserCircleIcon,
  WindowIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { MobileSidebar } from "./MobileSidebar";
import DesktopSidebar from "./DesktopSidebar";
import { Dispatch, SetStateAction, useState } from "react";
import {webRoutes} from "../routes";

export type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export type NavigationProps = {
  navigation: PrimaryNavigation[];
  secondaryNavigation: Navigation[];
  currentPage: PageName;
};

type Props = {
  currentPage: PageName;
};

export function SidebarNavigation({ currentPage }: Props) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <>
      <MobileSidebar
        navigation={navigation}
        secondaryNavigation={secondaryNavigation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        currentPage={currentPage}
      />
      <DesktopSidebar
        navigation={navigation}
        secondaryNavigation={secondaryNavigation}
        currentPage={currentPage}
      />
      <div className="flex flex-1 flex-col lg:pl-64 lg:hidden">
        <div className="flex lg:h-0 h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
}

export enum PageName {
  Home = "Home",
  Profile = "Profile",
  Logout = "Logout",
  Upgrade = "Upgrade",
  PricingConfigurationNew = "PricingConfigurationNew",
  PricingConfiguration = "PricingConfiguration",
}

const navigation: PrimaryNavigation[] = [
  { name: PageName.Home, href: "/dashboard", icon: HomeIcon, current: true },
];
const secondaryNavigation: Navigation[] = [
  { name: PageName.Profile, href: "/profile", icon: UserCircleIcon },
  {
    name: PageName.Upgrade,
    href: webRoutes.plans.upgrade(),
    icon: RocketLaunchIcon,
  },
  { name: PageName.Logout, href: "/logout", icon: WindowIcon },
];
type Navigation = {
  name: PageName;
  href: string;
  icon: any;
};
type PrimaryNavigation = Navigation & { current: boolean };
