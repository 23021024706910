import { PriceConfigDto } from "@pricing/shared";
import TextInputWithLabel from "./TextInputWithLabel";
import { FormButton } from "./FormButton";
import { FormEvent } from "react";

export type OnFormSubmit = (e: FormEvent<HTMLFormElement>) => Promise<void>;

export type PriceConfigFormProps = {
  priceConfig: PriceConfigDto | undefined;
  isLoading: boolean;
  onSubmit: OnFormSubmit;
};
export const PriceConfigForm = ({
  priceConfig,
  onSubmit,
  isLoading,
}: PriceConfigFormProps) => (
  <form className="space-y-8 divide-gray-200" onSubmit={onSubmit}>
    <div className="pt-6">
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-2">
          <TextInputWithLabel
            label="Name your configuration"
            fieldName="name"
            error=""
            validateOnInputExit={() => {}}
            value={priceConfig?.name ?? ""}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="mt-6 gap-y-6 gap-x-4 grid grid-cols-1 sm:grid-cols-6 flex flex-row items-end">
        <div className="sm:col-span-3">
          <TextInputWithLabel
            label="URL to get prices from"
            fieldName="url"
            error=""
            validateOnInputExit={() => {}}
            value={priceConfig?.url ?? ""}
            disabled={isLoading}
          />
        </div>
        <div className="flex col-start-5">
          <FormButton
            text="Save"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  </form>
);
