import { useAuth0 } from "@auth0/auth0-react";
import { FormEvent } from "react";
import { FormButton } from "./FormButton";
import {apiUrl} from "../api";
import {ApiRoutes} from "@pricing/shared";

async function upgradePlan(params: { plan: string }, token: string) {
  return await fetch(`${apiUrl}/${ApiRoutes.plans.upgrade()}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}

export const UpgradePlanForm = () => {
  const { getAccessTokenSilently } = useAuth0();

  const upgradeForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const elements = e.currentTarget.elements;

    const plan = (elements.namedItem("plan") as HTMLInputElement).value;

    const token = await getAccessTokenSilently();


    await upgradePlan({ plan }, token);
  };

  return (
    <form
      className="space-y-8 divide-y divide-gray-200"
      onSubmit={(e) => upgradeForm(e)}
    >
      <div className="space-y-6 divide-y divide-gray-200">
        <div>
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
            Upgrade your plan
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            The upgrade to basic plan is only a temporary step - when a user
            signs up, they should automatically be on the basic plan
          </p>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <input type="hidden" name="plan" value="basic" />
            <FormButton
              text="Upgrade to basic plan"
              type="submit"
              isLoading={false}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
