import { FormEvent, useState } from "react";
import Notification, { Level } from "../components/Notification";
import { ApiRoutes } from "@pricing/shared";
import { useAuth0 } from "@auth0/auth0-react";
import { apiUrl } from "../api";
import { PriceConfigForm } from "../components/PriceConfigForm";

async function addNewPriceConfiguration(
  params: { url: string; name: string },
  token: string
): Promise<Response> {
  return await fetch(`${apiUrl}/${ApiRoutes.priceConfig.new()}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}

export default function NewPricingConfigurationPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [showSaved, setShowSaved] = useState(Boolean);

  async function submitForm(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const elements = e.currentTarget.elements;

    const url = (elements.namedItem("url") as HTMLInputElement).value;
    const name = (elements.namedItem("name") as HTMLInputElement).value;

    setShowSaved(false);
    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const response = await addNewPriceConfiguration({ url, name }, token);

      if (response.status >= 400 && response.status < 500) {
        setFormError(
          "There was an error with your form, please check it and try again"
        );
      } else {
        setFormError(null);
        setShowSaved(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8 pt-6">
        {formError && (
          <Notification
            message={formError}
            level={Level.Warn}
            onClickClose={() => {
              setFormError(null);
            }}
          />
        )}
        {showSaved && (
          <Notification
            message={"Saved"}
            level={Level.Success}
            onClickClose={() => {
              setShowSaved(false);
            }}
          />
        )}
        <div className="space-y-6 divide-y divide-gray-200">
          <div>
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
              New pricing configuration
            </h1>
          </div>
          <PriceConfigForm
            priceConfig={undefined}
            isLoading={isLoading}
            onSubmit={(e) => submitForm(e)}
          />
        </div>
      </div>
      )
    </>
  );
}
