import { classNames } from "../pages/DashboardPage";

type Props = {
  error: string | null;
  validateOnInputExit: (target: EventTarget & HTMLInputElement) => void;
  fieldName: string;
  value: string;
  disabled?: boolean;
};

export default function TextInput({
  error,
  validateOnInputExit,
  fieldName,
  value,
  disabled = false,
}: Props) {
  return (
    <input
      type="text"
      name={fieldName}
      id={fieldName}
      className={classNames(
        "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:opacity-75 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200",
        error
          ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
          : ""
      )}
      onBlur={(e) => validateOnInputExit(e.currentTarget)}
      defaultValue={value}
      disabled={disabled}
      aria-invalid={error ? "true" : undefined}
      aria-describedby={error ? "first-name-error" : undefined}
    />
  );
}
