import { useAuth0 } from "@auth0/auth0-react";
import { ApiRoutes, PriceConfigJobDto } from "@pricing/shared";
import { TablePagination } from "./components/TablePagination";
import useSWR from "swr";
import { apiUrl } from "./api";

function usePriceConfigJobs({ priceConfigId }: { priceConfigId: string }): {
  priceConfigJobs: PriceConfigJobDto[] | undefined;
  loading: boolean;
} {
  const { getAccessTokenSilently } = useAuth0();
  const { data: priceConfigJobs, error } = useSWR(
    [
      getAccessTokenSilently,
      `${apiUrl}/${ApiRoutes.priceConfig.jobs(priceConfigId)}`,
    ],
    fetchPriceConfigJobsWithToken
  );

  const isLoading = !error && !priceConfigJobs;

  return {
    priceConfigJobs,
    loading: isLoading,
  };
}

const fetchPriceConfigJobsWithToken = async ([getToken, url]: [
  getAccessToken: () => Promise<string>,
  url: string
]): Promise<PriceConfigJobDto[]> => {
  const token = await getToken();

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => res.json());
};
export const PriceConfigJobsTable = (props: { priceConfigId: string }) => {
  const { priceConfigJobs, loading: priceConfigJobsLoading } =
    usePriceConfigJobs({ priceConfigId: props.priceConfigId });

  if (!priceConfigJobs || priceConfigJobsLoading) {
    return <></>;
  }

  return (
    <div className="sm:block">
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 mt-5 flex flex-col">
        <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="divide-y divide-gray-200 bg-gray-50">
              <tr>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  Run ID
                </th>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  Price
                </th>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  Currency
                </th>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  Started at
                </th>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  Finished at
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {priceConfigJobs.map((config) => (
                <tr key={config.jobId} className="bg-white hover:bg-gray-100">
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    {config.jobId}
                  </td>
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    {config.price}
                  </td>
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    {config.currency}
                  </td>
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    {config.startedAt}
                  </td>
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    {config.finishedAt}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <TablePagination
            usePagination={false}
            numberOfResults={priceConfigJobs.length}
          ></TablePagination>
        </div>
      </div>
    </div>
  );
};
