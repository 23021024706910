import { Link } from "react-router-dom";
import { usePriceConfigs } from "../usePriceConfigs";
import { TablePagination } from "./TablePagination";
import { ReactComponent as LoadingSpinner } from "../svg/LoadingSpinner.svg";
import { webRoutes } from "../routes";

export function PriceConfigTable() {
  const { priceConfigs, loading } = usePriceConfigs();

  if (loading) {
    return (
      <div className="flex">
        <div className="flex self-center justify-center m-auto mt-14 h-40 w-40">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (!priceConfigs || priceConfigs.length === 0) {
    return (
      <div className="flex">
        <div className="flex self-center justify-center m-auto mt-14 h-40 w-40">
          Unable to load price configs
        </div>
      </div>
    );
  }

  return (
    <div className="sm:block">
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 mt-5 flex flex-col">
        <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="divide-y divide-gray-200 bg-gray-50">
              <tr>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  Price Config ID
                </th>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  Name
                </th>
                <th className="px-6 py-4 text-sm font-semibold text-gray-900 text-left">
                  For URL
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {priceConfigs.map((config) => (
                <tr
                  key={config.priceConfigId}
                  className="bg-white hover:bg-gray-100"
                >
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    <Link
                      to={webRoutes.priceConfiguration.id(config.priceConfigId)}
                    >
                      {config.priceConfigId}
                    </Link>
                  </td>
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    <Link
                      to={webRoutes.priceConfiguration.id(config.priceConfigId)}
                    >
                      {config.name}
                    </Link>
                  </td>
                  <td className="max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    <Link
                      to={webRoutes.priceConfiguration.id(config.priceConfigId)}
                    >
                      {config.url}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <TablePagination
            usePagination={false}
            numberOfResults={priceConfigs.length}
          ></TablePagination>
        </div>
      </div>
    </div>
  );
}
