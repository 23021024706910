import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { UserProfileDto } from "@pricing/shared";
import useSWR from "swr";
import { apiUrl } from "./api";

const fetchWithToken = async (
  [getToken, getClaims]: [
    getAccessToken: () => Promise<string>,
    getIdTokenClaims: () => Promise<IdToken>
  ],
  method = "GET"
) => {
  const [token, claims] = await Promise.all([getToken(), getClaims()]);

  const userId = claims["pricing/user_id"];

  return fetch(`${apiUrl}/user/${userId}/profile`, {
    method,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => res.json());
};

export function useUser(): { user: UserProfileDto | null; loading: boolean } {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const { data: user, error } = useSWR(
    [getAccessTokenSilently, getIdTokenClaims],
    fetchWithToken
  );

  const isLoading = !error && !user;

  return { user, loading: isLoading };
}

