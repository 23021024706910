export const fetchWithToken = async ([getToken, url]: [
  getAccessToken: () => Promise<string>,
  url: string
]) => {
  const token = await getToken();

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => res.json());
};
